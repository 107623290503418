import * as React from "react";
import { useEffect } from "react";

import Hero from "../components/hero";
import Layout from "../components/layout";
import MemorialsFilter from "../components/memorials-filter";
import TrustpilotCarousel from "../components/trustpilot/trustpilot-carousel";

const MemorialGrid = (props: any) => {
  useEffect(() => {
    document.title = "Memorial pages - Fenix Funeral Directors" || "";
  }, []);

  return (
    <Layout>
      <Hero
        sectionClassName="hero-section hero-section--memorials pl-4 pr-4"
        bgImageClassName="hero-image hero-image--blog"
        contentClassName="hero-content hero-content--blog"
        altImages="Fenix Funeral Directors"
        imageGlow={"/images/glow.png"}
        breadcrumb={[
          { name: "Fenix Home", slug: "/" },
          { name: "Funerals and Memorial", slug: "/memorial/" },
        ]}
      ></Hero>
      <MemorialsFilter />
      <TrustpilotCarousel />
    </Layout>
  );
};

export default MemorialGrid;
