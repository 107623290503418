import * as React from "react";
import { useEffect, useState } from "react";
import { Link } from "gatsby";

export default function MemorialsFilter(props: any) {
  const [memorialsIda, setMemorialsIda] = useState<any>([]);

  const [loadMore, setLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState<any>([]);
  const [memorialsList, setMemorialsList] = useState<any>([]);
  const [searchInput, setSearchInput] = useState("");

  const [filteredResults, setFilteredResults] = useState(memorialsIda);

  useEffect(() => {
    fetch(
      process.env.FENIX_LOCATION_AJAX_URL +
        "_seo-scripts/memorial/memorials.json"
    )
      .then((response) => {
        if (response) {
          console.log("response", response);
          return response.json();
        }
        throw new Error("Fetch error");
      })
      .then((memorialsDataIda: any) => {
        //update memorial data from IDA to remote file
        fetch(process.env.FENIX_LOCATION_AJAX_URL + "_seo-scripts/memorial/");
        setMemorialsIda(memorialsDataIda?.list);
        setMemorialsList(memorialsDataIda?.list.slice(0, 15));
        setHasMore(memorialsDataIda?.length > 15);
      })
      .catch((error) => {
        console.log("response error", error);
      });
  }, []);

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = memorialsList.length;
      const isMore = currentLength < memorialsIda.length;
      const nextResults = isMore
        ? memorialsIda.slice(currentLength, currentLength + 15)
        : [];
      setMemorialsList([...memorialsList, ...nextResults]);
      setLoadMore(false);
    }
    const isMore = memorialsList.length < memorialsIda.length;
    setHasMore(isMore);
  }, [loadMore, hasMore, memorialsList]);

  const searchItems = (searchValue: any) => {
    setSearchInput(searchValue);
    if (searchInput != "") {
      const filteredData = memorialsIda.filter((item: any) => {
        return item.name.toLowerCase().includes(searchInput.toLowerCase());
        //indexOf(search, start) !== -1; includes
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(memorialsIda);
    }
  };

  function handleChange(e: any) {
    searchItems(e.target.value);
  }

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  return (
    <div className="filter-section filter-section--memorials pb-5 pl-xl-4 pr-xl-4">
      <div className="filter-wrapper mb-0 pb-xl-5">
        <div
          className={
            "search-box d-flex flex-column flex-lg-row justify-content-center row no-gutters mb-0 mb-xl-4 pb-0 pb-xl-3 pl-4 pr-4 pl-xl-0 pr-xl-0"
          }
        >
          <div className="col-12 pr-xl-4 text-white">
            <h1 className={"hero-content__title"}>
              Funerals and Memorial pages
            </h1>
            <div className="hero-content__subtitle pb-1">
              <div className="pt-1 pb-4 mb-0">
                As a family member or friend, you can share a loving memory on
                our memorial pages.
              </div>
            </div>
          </div>
          <div className="col-12 pb-2 mb-4 pb-xl-0 mb-xl-0">
            <input
              id="search-input"
              type="text"
              placeholder="search memorials"
              value={searchInput}
              onChange={handleChange}
              onKeyUp={handleChange}
            />
            <span></span>
          </div>
        </div>
        <div className="search-result memorial-list row no-gutters mt-4 pl-4 pr-4 pl-xl-0 pr-xl-0">
          {searchInput.length <= 1 ? (
            memorialsList?.map((item: any, index: any) => {
              const coverImage = item.photo_thumb;
              const currentDate = new Date();
              const ceremonyDate =
                item.time !== null ? new Date(item.time) : null;
              const dateOfCeremony = new Date(item.time + "T00:00:00Z");
              const formattedDateOfCeremony = dateOfCeremony
                .toLocaleString("en-GB", {
                  month: "short",
                  day: "2-digit",
                  timeZone: "UTC",
                })
                .toUpperCase();
              return (
                <div
                  key={index}
                  className={"col-12 col-md-6 col-xl-4 memorial-col mb-3"}
                >
                  <div className={"memorial-wrapper d-flex flex-column "}>
                    <div className="memorial-content d-flex flex-column justify-content-between text-white w-100 h-100">
                      <div className="memorial-content__wrapper h-100 text-center d-flex flex-column justify-content-between">
                        <div className="memorial-content__head">
                          {coverImage && (
                            <img
                              src={coverImage}
                              alt={item.name}
                              className="memorial-content__bg"
                              loading="lazy"
                            />
                          )}
                          <img
                            src="/images/icons/icon_flower-white.svg"
                            alt="memorial icon"
                            className="memorial-content__icon"
                            width={70}
                            height={70}
                          />
                          {item.name && (
                            <h2 className="memorial-content__title mb-4 mb-xl-1 mt-5 text-center mx-auto">
                              {item.name}
                            </h2>
                          )}
                          {item.date && (
                            <p className="memorial-content__dates mt-2 mb-4 text-center mx-auto">
                              {item.date}
                            </p>
                          )}
                        </div>
                        <div className="memorial-content__body d-none d-xl-block mx-auto">
                          <p className="memorial-content__family text-center">
                            {item.obituary
                              ? item.obituary
                              : "In loving memory of " +
                                item.name.split(" ")[0] +
                                ". Rest in Peace."}
                          </p>
                        </div>
                        <div className="memorial-content__footer d-flex flex-column align-items-center justify-content-xl-between">
                          <Link
                            to={
                              ceremonyDate
                                ? item.url + "registration/"
                                : item.url
                            }
                            className={
                              ceremonyDate
                                ? "memorial-content__funeral d-flex flex-column justify-content-center align-items-center mb-1"
                                : "memorial-content__funeral memorial-content__button d-flex flex-column justify-content-center align-items-center"
                            }
                          >
                            {ceremonyDate
                              ? "Funeral " + formattedDateOfCeremony
                              : "Memorial page"}
                          </Link>
                          {ceremonyDate && (
                            <Link
                              to={item.url}
                              className="memorial-content__obituary d-flex justify-content-center align-items-center"
                              // state={{data:item}}
                            >
                              Memorial page
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : searchInput.length >= 2 && filteredResults?.length >= 1 ? (
            filteredResults.map((item: any, index: any) => {
              const coverImage = item.photo;
              const currentDate = new Date();
              const ceremonyDate =
                item.time !== null ? new Date(item.time) : null;
              const dateOfCeremony = new Date(item.time + "T00:00:00Z");
              const formattedDateOfCeremony = dateOfCeremony
                .toLocaleString("en-GB", {
                  month: "short",
                  day: "2-digit",
                  timeZone: "UTC",
                })
                .toUpperCase();
              return (
                <div
                  key={index}
                  className={"col-12 col-md-6 col-xl-4 memorial-col mb-3"}
                >
                  <div className={"memorial-wrapper d-flex flex-column "}>
                    <div className="memorial-content d-flex flex-column justify-content-between text-white w-100 h-100">
                      <div className="memorial-content__wrapper h-100 text-center d-flex flex-column justify-content-between">
                        <div className="memorial-content__head">
                          {coverImage && (
                            <img
                              src={coverImage}
                              alt={item.name}
                              className="memorial-content__bg"
                              loading="lazy"
                            />
                          )}
                          <img
                            src="/images/icons/icon_flower-white.svg"
                            alt="memorial icon"
                            className="memorial-content__icon"
                            width={70}
                            height={70}
                          />
                          {item.name && (
                            <h2 className="memorial-content__title mb-4 mb-xl-1 mt-5 text-center mx-auto">
                              {item.name}
                            </h2>
                          )}
                          {item.date && (
                            <p className="memorial-content__dates mt-2 mb-4 text-center mx-auto">
                              {item.date}
                            </p>
                          )}
                        </div>
                        <div className="memorial-content__body d-none d-xl-block mx-auto">
                          <p className="memorial-content__family text-center">
                            {item.obituary
                              ? item.obituary
                              : "In loving memory of " +
                                item.name.split(" ")[0] +
                                ". Rest in Peace."}
                          </p>
                        </div>
                        <div className="memorial-content__footer d-flex flex-column align-items-center justify-content-xl-between">
                          <Link
                            to={
                              ceremonyDate
                                ? item.url + "registration/"
                                : item.url
                            }
                            className={
                              item.time === null
                                ? "memorial-content__funeral d-flex flex-column justify-content-center align-items-center mb-3"
                                : "memorial-content__funeral d-flex flex-column justify-content-center align-items-center mb-3"
                            }
                          >
                            {ceremonyDate
                              ? "Funeral " + formattedDateOfCeremony
                              : "Memorial page"}
                          </Link>
                          {ceremonyDate && (
                            <Link
                              to={item.url}
                              className="memorial-content__obituary d-flex justify-content-center align-items-center"
                              state={{ data: item }}
                            >
                              Memorial page
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : searchInput.length > 2 && filteredResults?.length === 0 ? (
            <div
              className={
                "col-12 memorial-col mb-4 d-flex justify-content-center align-items-center"
              }
            >
              <h1 className={"text-white"}>
                Your search for {searchInput} gave 0 hits
              </h1>
            </div>
          ) : (
            ""
          )}
        </div>
        {hasMore && memorialsIda?.length > 15 && searchInput.length === 0 ? (
          <div className="d-flex justify-content-center mt-4">
            <button
              className={
                "btn btn--more bg-white text-deepblue d-flex align-items-center justify-content-center text-center"
              }
              onClick={handleLoadMore}
            >
              Load More
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
